@media screen and (max-width: 650px) {
  .nav {
    flex-flow: column;
  }
  
  .routes {
    padding-top: 15px;
    
    .link {
      margin: 0 15px 20px 0 !important;
    }

    .username {
      display: block;
    }
  }
}

.nav {
  margin-bottom: 30px;
  background-color: #222;
  display: flex;
  width: 100%;
  
  .routes {
    margin: auto;
    max-width: 1000px;
    
    .logout {
      margin: 0 !important;
      color: white;
    }
    
    .link {
      display: inline-flex;
      margin: auto 50px auto 0;
      text-decoration: none;
      font-weight: bold;
      color: lightgray;
      cursor: pointer;
      font-size: 16px;
      
      &.active {
        color: white;
      }
    }
  }
  
  img {
    object-fit: contain;
    height: 100%;
    max-height: 85px;
    align-self: flex-start;
  }
}