@use "./variables";

.orbit-spinner, .orbit-spinner * {
  box-sizing: border-box;
}

.orbitSpinner {
  margin-left: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  perspective: 800px;
}

.orbitSpinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.orbitSpinner .orbit:nth-child(1) {
  left: 0;
  top: 0;
  animation: orbitSpinner-orbit-one-animation 1200ms linear infinite;
  border-bottom: 3px solid variables.$orange;
}

.orbitSpinner .orbit:nth-child(2) {
  right: 0;
  top: 0;
  animation: orbitSpinner-orbit-two-animation 1200ms linear infinite;
  border-right: 3px solid variables.$orange;
}

.orbitSpinner .orbit:nth-child(3) {
  right: 0;
  bottom: 0;
  animation: orbitSpinner-orbit-three-animation 1200ms linear infinite;
  border-top: 3px solid variables.$orange;
}

@keyframes orbitSpinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes orbitSpinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes orbitSpinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}