@use "./variables";

.keyword {
  color: #6C95EB;
}
.constant {
  color: #ed94c0;
}
.method {
  color: variables.$light-orange;
}

.class {
  color: #c191ff;
}

.property {
  color: #66C3CC;
}