.link {
  margin: 0 50px 0 0;
  text-decoration: none;
  font-weight: bold;
  color: white;
  line-height: 2rem;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border: none;
  
  &.active {
    color: #ff914d;
  }
}

.standardLinks {
  display: flex;
  flex-flow: wrap;
  text-align: left;
  margin-bottom: 30px;
  justify-content: center;
}

.standardContent {
  width: 100%;
  text-align: left;
}

.indent {
  padding: 0 1rem;
}