@use "variables.scss";

body {
  margin: 0;
  background-color: #333;
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
}

.content {
  margin: 0 auto;
  max-width: 1000px;
  width: calc(100% - 10px);
}

a {
  color: variables.$green;
  text-decoration: none;
}

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: lightgray;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  
  & main {
    flex-grow: 1;
  }
}

hr {
  background-color: variables.$orange;
  border: none;
  height: 1px;
  margin: 15px;
}

h3 {
  color: variables.$green;
}

.indent {
  padding: 0 1rem;
}

.underline {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 7px;
    width: 100%;
    border: solid 2px variables.$orange;
    border-color: variables.$orange transparent transparent transparent;
    border-radius: 30%;
  }
}

.centerText {
  text-align: center;
}