.footer {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  align-self: end;
  
  & svg path {
    fill: #0a66c2;
  }
  
  .contact {
    margin-left: 50px;
  }
}

