@use "./variables";

.kirkleInput {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-size: 3rem;
  border: 2px solid variables.$grey;
  border-radius: 1rem;
  background: black;
  margin: 0 0 0.25rem 0.25rem;
  color: white;
}

.guesses {
  margin-bottom: 2rem;
  max-width: calc(100% - 50px);
}

.guess {
  display: grid;
  grid-template-columns: repeat(5, max-content) auto;
  justify-content: center;
  position: relative;
  left: 30px;
}

.spinner {
  display: inline-flex;
  align-items: center;
  align-self: end;
  height: 60px;
  width: 60px;
  color: red;
  font-size: 3rem;
}

.correct {
  background: variables.$orange;
  color: white !important;
  border: 1px solid white;
}

.miss {
  background: mediumslateblue;
  color: white !important;
  border: 1px solid white;
}

.wrong {
  background: variables.$dark-grey;
  color: white !important;
  border: 1px solid variables.$dark-grey;
}

.unused {
  background: variables.$grey;
  color: white;
  border: 1px solid variables.$orange;
}

.flip {
  animation: flip-letter 500ms ease forwards;  
}

@keyframes flip-letter {
  0% {
    background: black;
    transform: scaleY(1);
  }
  
  50% {
    background: black;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: variables.$dark-grey;
  padding: 1rem;
  border: 2px solid variables.$grey;
  border-radius: 8px;
}

.share {
  background: dodgerblue;
  border: 1px solid variables.$grey;
  border-radius: 8px;
  color: white;
  padding: 1rem;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
  
  &:hover {
    border-color: white;
  }
  
  &:active {
    border-color: variables.$orange;    
  }
}

.heading {
  display: flex;
  
  & .close {
    display: flex;
    justify-content: flex-end;
    margin-left: 2rem;
    
    a {
      color: variables.$light-orange;
    }
  }
  
  & .correctWord {
    color: variables.$orange;  
    padding: 0 0.5rem;
  }
}

.actions {
  display: flex;
  justify-content: center;
}

.textCopied {
  display: flex;
  justify-content: center;
  width: 50%; 
  margin: 0 auto;
  padding-top: 0.25rem;
  color: variables.$light-orange;
  height: 0.4rem;
}  