@use "./variables";

.code {
  margin: 15px 0 15px 0;
  padding: 15px;
  border: 1px solid variables.$green;
  background-color: variables.$dark-grey;
  color: lightgray;
  font-family: monospace;

  &.badExample {
    border: 1px solid red;
    border-left: 20px solid red;
  }

  &.goodExample {
    border: 1px solid variables.$green;
    border-left: 20px solid variables.$green;
  }

  &.avoidExample {
    border: 1px solid variables.$light-orange;
    border-left: 20px solid variables.$light-orange;
  }

  .codeHeader {
    position: relative;
    float: right;
    display: flex;
    margin-bottom: 15px;
    color: variables.$light-orange;
  }
  h3 {
    margin: 0 0 15px;
    &.goodExample {
      color: variables.$green;
    }
    &.badExample {
      color: lightcoral;
    }

    &.avoidExample {
      color: variables.$light-orange;
    }
  }

  .language {
    float: right;
    width: 100%;
  }
}