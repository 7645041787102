@use "./variables";

.banner {
  display: flex;
  flex-flow: wrap-reverse;
  align-items: center;
  justify-content: center;
  background: variables.$grey;
}
img {
  object-fit: scale-down;
  max-width: 350px;
}
.partners {
  display: flex;
  flex-flow: wrap;
  justify-content: center;

  & img {
    height: 150px;
  }
}