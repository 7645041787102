@use "./common.module";
@use "./variables";

.accordionContainer {
  margin: 15px 0;
}

.accordionHeader {
  display: flex;
  border: 1px solid white;
  background-color: variables.$grey;
  align-items: baseline;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;

  &.open {
     border-radius: 10px 10px 0 0;
     border-bottom: 0;
   }
  }

.accordionHeaderContent {
  flex-grow: 1;
}

.accordionBody {
  padding: 15px 15px;
  border: 1px solid white;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  background-color: variables.$grey;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  height: 2px;

  &.open {
       transform: rotate(-135deg);
       -webkit-transform: rotate(-135deg);
   }
  
  &.close {
       transform: rotate(45deg);
       -webkit-transform: rotate(45deg);
  }
}