@use "./variables";

.keyboard {
  display: flex;
  justify-content: center;
  max-width: 100%;

  & .button {
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    margin: 0.25rem;
    border-radius: 0.5rem;
    font-weight: bold;

    &:active {
      background: variables.$light-orange;
      color: variables.$grey;
    }

    &.wide {
      width: 70px;
    }

    .backspace {
      fill: white;
    }
  }
}